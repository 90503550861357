<template>
  <div class="content-wrapper user-page">
    <h1 class="mb-5">People Analytics</h1>
    <b-overlay
      :show="showOverlayGeneral"
      rounded="sm"
      class="overlay-placeholder"
    >
      <template #overlay>
        <div class="w-100 d-flex align-items-start justify-content-center pt-3">
          <img
            src="../../public/images/whappy-loading.gif"
            style="width:200px;height:auto;"
          />
        </div>
      </template>

      <!-- FILTER  -->
      <b-card class="mb-4" style="overflow: visible !important">
        <b-form-group>
          <b-row>
            <b-col cols="7">
              <b-row class="mb-2">
                <b-col>
                  <b-form-select
                    @change="getCampaignData"
                    v-model="campaignSelected"
                    :options="campaignFilteredByCompany"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-select
                    v-model="roleSelected"
                    :options="roleOptions"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <b-form-select
                    v-model="areaSelected"
                    :options="areaOptions"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <b-form-select
                    v-model="departmentSelected"
                    :options="departmentOptions"
                  ></b-form-select>
                </b-col>
                <b-col>
                  <b-form-select
                    v-model="clusterSelected"
                    :options="clusterOptions"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="5">
              <b-row>
                <b-col>
                  <div class="d-flex flex-column align-items-start">
                    <label
                      for="datepicker"
                      class="ml-2 text-left"
                      style="min-width: 3rem"
                      >From</label
                    >
                    <b-form-datepicker
                      id="datepicker"
                      v-model="datepickerValue"
                      class="mb-2"
                      :date-format-options="{
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      }"
                      dropup
                    ></b-form-datepicker>
                  </div>
                </b-col>
                <b-col>
                  <div class="d-flex flex-column align-items-start">
                    <label
                      for="datepicker2"
                      class="ml-2 text-left"
                      style="min-width: 3rem"
                    >
                      To</label
                    >
                    <b-form-datepicker
                      id="datepicker2"
                      v-model="datepickerValue2"
                      class="mb-2"
                      :date-format-options="{
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      }"
                      dropup
                    ></b-form-datepicker>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="auto">
              <b-button @click="caricaDati">Carica dati</b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-card>

      <!-- TABLE - USERS LIST  -->
      <b-card>
        <b-table
          hover
          :items="partnerList"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          primary-key="nomecompleto"
        >
          <template #cell(Nome)="row">
            <div class="d-flex align-items-center">
              <b-avatar
                class="mr-3"
                :src="row.item.ProfileImage"
                size="70px"
              ></b-avatar>
              {{ row.item.Nome }} {{ row.item.Cognome }}
            </div>
          </template>
          <template #cell(socials)="row">
            <b-icon-facebook
              class="icon-midsize"
              v-if="row.item.WithFacebook"
              color="#2e89ff"
            ></b-icon-facebook>
            <b-icon-facebook
              class="icon-midsize"
              v-else
              color="#b0b0b0"
            ></b-icon-facebook>
            <b-icon-linkedin
              class="icon-midsize"
              v-if="row.item.WithLinkedin"
              color="#0a66c2"
            ></b-icon-linkedin>
            <b-icon-linkedin
              class="icon-midsize"
              v-else
              color="#b0b0b0"
            ></b-icon-linkedin>
          </template>
          <template #cell(policy)="row">
            <div v-if="row.item.DataPrivacy">
              {{
                row.item.DataPrivacy.slice(8, 10) +
                  "/" +
                  row.item.DataPrivacy.slice(5, 7) +
                  "/" +
                  row.item.DataPrivacy.slice(2, 4) +
                  " - " +
                  row.item.DataPrivacy.slice(11, 16)
              }}
            </div>
          </template>
          <template #cell(attivo)="row">
            <b-icon-check
              class="icon-midsize"
              v-if="row.item.attivo"
            ></b-icon-check>
            <b-icon-x class="icon-midsize" v-else></b-icon-x>
          </template>
          <template #cell(actions)="row">
            <b-icon-graph-up
              v-b-toggle.sidebar-partners
              style="cursor: pointer"
              @click="loadUser(row.item)"
            >
            </b-icon-graph-up>
          </template>
          <template #cell(AdrIndex)="row">
            <div class="d-flex w-100">
              <div class="flex-grow-1 mr-3">
                <div class="d-flex align-items-center justify-content-around">
                  <div class="mr-2">Sessioni: {{ row.item.Sessioni }}</div>
                  <b-progress
                    style="width: 65%"
                    height="8px"
                    :value="row.item.Sessioni"
                    :max="maxSessioni"
                  >
                  </b-progress>
                </div>
                <div class="d-flex align-items-center justify-content-around">
                  <div class="mr-2">Badges: {{ row.item.N_Badges }}</div>
                  <b-progress
                    style="width: 65%"
                    height="8px"
                    :value="row.item.N_Badges"
                    :max="maxBadges"
                  ></b-progress>
                </div>
                <div class="d-flex align-items-center justify-content-around">
                  <div class="mr-2">Awards: {{ row.item.N_Awards }}</div>
                  <b-progress
                    style="width: 65%"
                    height="8px"
                    :value="row.item.N_Awards"
                    :max="maxAwards"
                  ></b-progress>
                </div>
              </div>
              <div class="table-pie-chart">
                <simple-pie-chart
                  :labels="['', '']"
                  :pieData="row.item.pieData"
                  :hasLegend="false"
                ></simple-pie-chart>
              </div>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>

      <!-- SIDEBAR -->
      <b-sidebar
        class="right-sidebar"
        id="sidebar-partners"
        right
        shadow
        lazy
        @hidden="resetSidebar"
      >
        <div class="content-wrapper my-3 mx-1">
          <div class="sidebar-header d-flex flex-column mb-3">
            <h4>{{ tmpItem.Nome }} {{ tmpItem.Cognome }}</h4>
            <div class="upload-photo-cicrle">
              <b-avatar size="6rem" :src="tmpItem.ProfileImage"></b-avatar>
            </div>

            <p style="margin-bottom: 0">Mail: {{ tmpItem.Mail }}</p>
            <p style="margin: 0">Ruolo: {{ tmpItem.Ruolo }}</p>
          </div>

          <b-overlay
            :show="showOverlaySidebar"
            rounded="sm"
            class="overlay-placeholder"
          >
            <b-tabs lazy content-class="mt-3" class="mt-3" ref="sidebar-table">
              <!-- ENGAGEMENT RATE  ENGAGEMENT RATE ENGAGEMENT RATE -->
              <b-tab
                @click="loadTab"
                title="Engagement Rate"
                active
                class="mx-3 mt-4"
              >
                <b-row>
                  <b-col>
                    <b-progress :value="tmpItem.percCompletamento"></b-progress>
                  </b-col>
                </b-row>

                <div class="charts-container">
                  <!-- <simple-pie-chart
                    :labels="[`User`, `Group`]"
                    :pieData="[fintiDati.userTotTime, fintiDati.groupTotTime]"
                    :legend="[
                      `Total time session User ${fintiDati.userTotTime}`,
                      `Group ${fintiDati.groupTotTime}`,
                    ]"
                  ></simple-pie-chart> -->

                  <simple-pie-chart
                    :labels="[`User`, `Group`]"
                    :pieData="[this.tmpItem.Sessioni, this.totSessioni]"
                    :legend="[
                      'Total n session user ' + this.tmpItem.Sessioni,
                      'Group ' + this.totSessioni,
                    ]"
                    :infoPopover="'Sessions for groups'"
                  ></simple-pie-chart>
                  <!-- 
                  <simple-pie-chart
                    :labels="[`User`, `Group`]"
                    :pieData="[fintiDati.userAvgTime, fintiDati.groupAvgTime]"
                    :legend="[
                      'Avg time each session ' + fintiDati.userAvgTime,
                      'Group ' + fintiDati.groupAvgTime,
                    ]"
                  ></simple-pie-chart> -->
                </div>

                <b-row>
                  <b-col>
                    <line-chart
                      :chartData="charDataArr"
                      :chartLabels="chartLabelsArr"
                    ></line-chart>
                  </b-col>
                </b-row>
              </b-tab>

              <!-- LEARNING LEARNING LEARNING  -->
              <b-tab @click="loadTab" title="Learning" class="mx-3 mt-4">
                <learning-component
                  :dataLearning="learningTabData"
                  :campaignSelected_text="campaignSelected_text"
                >
                </learning-component>
              </b-tab>

              <b-tab @click="loadTab" disabled title="Tasks"></b-tab>

              <!-- SOCIAL SHARE SOCIAL SHARE SOCIAL SHARE  -->
              <b-tab @click="loadTab" title="Social Share" class="mx-3 mt-4">
                <social-share-component
                  :showGraph="showOverlaySidebar"
                  :dataSocialShare="socialShareTabData"
                  :campaignSelected_text="campaignSelected_text"
                ></social-share-component>
              </b-tab>

              <b-tab @click="loadTab" title="KPIs" class="mx-3">
                <kpi-component
                  :dataKpi="kpiTabData"
                  :campaignSelected_text="campaignSelected_text"
                ></kpi-component>
              </b-tab>
              <b-tab @click="loadTab" title="Badges and Awards">
                <badge-and-awards-component
                  :dataBadgeAndAwards="badgesAndAwardsTabData"
                  :nomeCognome="tmpItem.Nome + ' ' + tmpItem.Cognome"
                  :campaignSelected_text="campaignSelected_text"
                >
                </badge-and-awards-component>
              </b-tab>
            </b-tabs>
          </b-overlay>
        </div>
      </b-sidebar>
    </b-overlay>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";
import { mapGetters } from "vuex";
import LineChart from "../components/LineChart.vue";
// import DoughnutChart from "../components/DoughnutChart.vue";
// import TitlePie from "../components/DashBoard/TabTitles/TitlePie.vue";
import SimplePieChart from "../components/SimplePieChart.vue";
// import { first } from "@amcharts/amcharts5/.internal/core/util/Array";
import LearningComponent from "../components/PeopleAnalytics/Learning_component.vue";
import SocialShareComponent from "../components/PeopleAnalytics/SocialShare_component.vue";
import KpiComponent from "../components/PeopleAnalytics/Kpi_component.vue";
import BadgeAndAwardsComponent from "../components/PeopleAnalytics/BadgeAndAwards_component.vue";
import { SET_CURRENT_COMPANY } from "../store/actions/config";

export default {
  components: {
    SimplePieChart,
    LineChart,
    LearningComponent,
    SocialShareComponent,
    KpiComponent,
    BadgeAndAwardsComponent,
    // DoughnutChart,
    // TitlePie,
  },
  data() {
    return {
      fintiDati: {
        userTotTime: 20,
        groupTotTime: 50,
        userNSession: 45,
        groupNSession: 562,
        userAvgTime: 12,
        groupAvgTime: 10,
      },

      companySelected: 0,
      companyOptions: [{ value: 0, text: "Select company" }],
      campaignSelected: 0,
      campaignOptions: [],
      roleSelected: 0,
      roleOptions: [{ value: 0, text: "Select role" }],
      datepickerValue: "",
      datepickerValue2: "",

      items: null,
      fields: [
        {
          key: "Nome",
          label: "Name",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "socials",
          label: "Socials",
          sortable: true,
          thStyle: { width: "8%" },
        },

        {
          key: "Ruolo",
          label: "Role",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "policy",
          label: "Policy",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "attivo",
          label: "Active",
          sortable: true,
          thStyle: { width: "7.5%" },
        },
        {
          key: "AdrIndex",
          label: "Performance Rate",
          thStyle: { width: "35%" },
          sortable: true,
        },

        { key: "actions", label: "Analysis", thStyle: { width: "5%" } },
      ],
      currentPage: 1,
      perPage: 10,

      tmpItem: {},

      file_photo: null,

      view_lang: null,

      partnerList: [],

      charDataArr: [],
      chartLabelsArr: [],

      firstTimeEngagementRate: true,
      firstTimeSocialShare: true,
      firstTimeLearning: true,
      firstTimeKpi: true,
      firstTimeBadges: true,
      firstTimeAwards: true,

      socialShareTabData: [],
      learningTabData: [],
      kpiTabData: [],
      tasksTabData: [],
      badgesAndAwardsTabData: { badges: [], awards: [] },

      showOverlayGeneral: false,
      showOverlaySidebar: false,

      maxSessioni: 0,
      maxBadges: 0,
      maxAwards: 0,

      primaryColor: "",
      secondaryColor: "",

      totSessioni: 0,

      areaSelected: 0,
      areaOptions: [{ value: 0, text: "Select area" }],

      departmentSelected: 0,
      departmentOptions: [{ value: 0, text: "Select department" }],

      clusterSelected: 0,
      clusterOptions: [{ value: 0, text: "Select cluster" }],

      campaignSelected_text: "",
    };
  },
  serviceCompany: null,
  serviceCampaigns: null,
  serviceRole: null,
  globalDataService: null,
  generalService: null,
  service: null,
  created() {
    const appElement = document.querySelector("#app");
    const style = getComputedStyle(appElement);
    this.primaryColor = style.getPropertyValue("--whappy-primary");
    this.secondaryColor = style.getPropertyValue("--whappy-secondary");

    this.service = new AxiosService("User");
    this.serviceCompany = new AxiosService("UserBO");
    this.serviceCampaigns = new AxiosService("Campaign");
    this.serviceRole = new AxiosService("Role");
    this.globalDataService = new AxiosService("Dashboard/GetDasboardUserData");

    this.resetSidebar();
  },
  async mounted() {
    let today = new Date();
    this.datepickerValue = new Date(today.getFullYear(), today.getMonth(), 1);
    this.datepickerValue2 = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    //get companyOptions
    await this.serviceCompany.getOptions("Company").then((data) => {
      data.forEach((element) => {
        this.companyOptions.push(element);
      });
    });

    this.companySelected = this.currentCompanyFromStore;
    //get campaignOptions
    this.serviceCampaigns.read().then((data) => {
      data.forEach((element) => {
        this.campaignOptions.push(element);
      });
    });

    this.getCampaignData();

    this.service.read().then((data) => {
      this.items = data;
    });

    this.service
      .getOptions("Company")
      .then((data) => (this.companyList = data));
  },
  methods: {
    loadTab() {
      let currentTab = this.$refs["sidebar-table"].currentTab;
      console.log(
        "🚀 ~ file: PeopleAnalytics.vue ~ line 534 ~ loadTab ~ currentTab",
        currentTab
      );
      let endPoint;

      switch (currentTab) {
        case 0:
          break;

        // Social Share
        case 2:
          endPoint = "Dashboard/GetSocialShareData";
          if (this.campaignSelected > 0) {
            // if (this.firstTimeSocialShare) {
            //   this.showOverlaySidebar = true;
            //   this.socialShareTabData.length = 0;
            //   this.generalService = new AxiosService(endPoint);
            //   let obj = {
            //     idCampaign: this.campaignSelected,
            //     idRole: 0,
            //     idCompany: this.companySelected,
            //     dataInizio: this.datepickerValue,
            //     dataFine: this.datepickerValue2,
            //   };
            //   this.generalService.create(obj).then((res) => {
            //     this.socialShareTabData = res;
            //     this.showOverlaySidebar = false;
            //   });
            //   this.firstTimeSocialShare = false;
            // }
          }
          break;

        // Learning
        case 1:
          endPoint = "Dashboard/GetDasboardUserData_Learning";

          if (this.campaignSelected > 0) {
            if (this.firstTimeLearning) {
              this.showOverlaySidebar = true;
              this.learningTabData.length = 0;
              this.generalService = new AxiosService(endPoint);
              let obj = {
                idCampaign: this.campaignSelected,
                idUser: this.tmpItem.Id,
                dataInizio: this.datepickerValue,
                dataFine: this.datepickerValue2,
              };

              this.generalService.create(obj).then((res) => {
                res.sezioni.forEach((element) => {
                  this.learningTabData.push(element);
                });
                this.showOverlaySidebar = false;
              });
              this.firstTimeLearning = false;
            }
          }

          break;

        // Kpi
        case 4:
          endPoint =
            "CampaignManager/GetUserKpiById/" +
            this.campaignSelected +
            "/" +
            this.tmpItem.Id;
          if (this.campaignSelected > 0) {
            if (this.firstTimeKpi) {
              this.showOverlaySidebar = true;
              this.kpiTabData.splice(1);
              this.generalService = new AxiosService(endPoint);

              this.generalService.read().then((res) => {
                this.kpiTabData = res;
                this.showOverlaySidebar = false;
              });
              this.firstTimeKpi = false;
            }
          }
          break;
        case 3:
          break;

        // Badges and awards
        case 5:
          endPoint =
            "CampaignManager/GetUserAward/" +
            this.campaignSelected +
            "/" +
            this.tmpItem.Id;
          if (this.campaignSelected > 0) {
            if (this.firstTimeAwards) {
              this.showOverlaySidebar = true;
              this.badgesAndAwardsTabData.awards.splice(0);
              this.generalService = new AxiosService(endPoint);

              this.generalService.read().then((res) => {
                res.forEach((element) => {
                  this.badgesAndAwardsTabData.awards.push(element);
                });
              });
              this.firstTimeAwards = false;
              if (this.firstTimeBadges) {
                endPoint =
                  "CampaignManager/GetUserBadges/" +
                  this.campaignSelected +
                  "/" +
                  this.tmpItem.Id;
                this.badgesAndAwardsTabData.badges.splice(0);
                this.generalService = new AxiosService(endPoint);

                this.generalService.read().then((res) => {
                  res.forEach((element) => {
                    this.badgesAndAwardsTabData.badges.push(element);
                  });
                  this.showOverlaySidebar = false;
                  this.firstTimeBadges = false;
                });
              }
            }
          }

          break;
        default:
      }
    },

    loadUser(item) {
      // this.tmpItem = JSON.parse(JSON.stringify({}));
      this.tmpItem = JSON.parse(JSON.stringify(item));

      this.charDataArr = [];
      this.chartLabelsArr = [];

      this.tmpItem.SessioniGiorno.forEach((element) => {
        this.charDataArr.push(element.ChartData[0]);
        this.chartLabelsArr.push(element.ChartLabel[0]);
      });
    },
    async caricaDati() {
      this.firstTimeEngagementRate = true;
      this.firstTimeSocialShare = true;
      this.firstTimeLearning = true;
      this.firstTimeKpi = true;
      this.firstTimeBadges = true;
      this.firstTimeAwards = true;
      this.totSessioni = 0;
      this.partnerList.length = 0;
      this.showOverlayGeneral = true;

      this.campaignSelected > 0
        ? (this.campaignSelected_text = "")
        : (this.campaignSelected_text =
            "Seleziona una campagna per poter vederne qui i dati relativi");

      let obj = {
        idCompany: this.companySelected,
        idCampaign: this.campaignSelected,
        idRole: this.roleSelected,
        idArea: this.areaSelected,
        idDepartment: this.departmentSelected,
        idCluster: this.clusterSelected,
        dataInizio: this.datepickerValue,
        dataFine: this.datepickerValue2,
      };

      await this.globalDataService.create(obj).then((res) => {
        if (res) {
          this.showOverlayGeneral = false;
          this.partnerList = res;
          let sessionsArr = [];
          let badgesArr = [];
          let awardsArr = [];
          let numberOfSocials = 0;

          res.forEach((element) => {
            element.pieData = [];
            numberOfSocials = 0;
            sessionsArr.push(element.Sessioni);
            badgesArr.push(element.N_Badges);
            awardsArr.push(element.N_Awards);
            element.WithFacebook ? numberOfSocials++ : numberOfSocials;
            element.WithLinkedin ? numberOfSocials++ : numberOfSocials;
            element.socials = numberOfSocials;
            element.AdrIndex = element.Sessioni;

            if (
              element.N_Badges + element.N_Awards != 0 &&
              element.Sessioni != 0
            ) {
              let somma =
                (element.N_Badges + element.N_Awards) / element.Sessioni;
              element.pieData.push(somma);
              element.pieData.push(1);
            } else if (
              element.Sessioni == 0 ||
              element.N_Badges + element.N_Awards == 0
            ) {
              element.pieData.push(0);
              element.pieData.push(1);
            }
          });

          this.totSessioni = sessionsArr.reduce((prev, next) => prev + next, 0);

          this.maxSessioni = Math.max(...sessionsArr);
          this.maxBadges = Math.max(...badgesArr);
          this.maxAwards = Math.max(...awardsArr);
        } else {
          console.log("Errore caricando i dati", res);
        }
      });
    },
    getCompanyData() {
      // get areas
      this.areaOptions.splice(1);
      this.service
        .readCustomEndpoint("Option/AT_Areas/" + this.companySelected)
        .then((res) => this.areaOptions.push(...res));

      // get departments
      this.departmentOptions.splice(1);
      this.service
        .readCustomEndpoint("Option/AT_Deparment/" + this.companySelected)
        .then((res) => this.departmentOptions.push(...res));

      // get clusters
      this.clusterOptions.splice(1);
      this.service
        .readCustomEndpoint("Option/AT_Cluster/" + this.companySelected)
        .then((res) => this.clusterOptions.push(...res));

      // get roles
      this.roleOptions.splice(1);
      this.service
        .readCustomEndpoint("Option/UserRole/" + this.companySelected)
        .then((res) => this.roleOptions.push(...res));
    },
    getCampaignData() {
      // set company campaign dates
      if (this.campaignSelected) {
        let campaignSelected = this.campaignFilteredByCompany.filter(
          (campaign) => campaign.id == this.campaignSelected
        );

        console.log(" get campaign data", campaignSelected);

        this.datepickerValue = campaignSelected[0].data_inizio;
        this.datepickerValue2 = campaignSelected[0].data_fine;
      }
    },
    resetSidebar() {
      this.tmpItem = {
        Cognome: "",
        Id: 0,
        Mail: "",
        MediaSessioni: 0,
        Nome: "",
        ProfileImage: "",
        Ruolo: "",
        Sessioni: 0,
        SessioniGiorno: [],
        WithFacebook: false,
        WithLinkedin: false,
        attivo: false,
        percCompletamento: 0,
        ChartData: [],
        ChartLabels: [],
      };
      (this.firstTimeEngagementRate = true),
        (this.irstTimeSocialShare = true),
        (this.firstTimeLearning = true),
        (this.firstTimeKpi = true),
        (this.firstTimeBadges = true),
        (this.firstTimeAwards = true),
        (this.file_photo = null);
      this.view_lang = this.default_lang.value || this.default_lang;
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),

    campaignFilteredByCompany() {
      let array = [{ text: "Select campaign", value: 0 }];
      this.campaignOptions.forEach((element) => {
        if (element.id_company == this.companySelected) {
          element.text = element.title["it-IT"];
          element.value = element.id;
          array.push(element);
        }
      });
      return array;
    },
    roleFilteredByCompany() {
      let array = [{ text: "Select role", value: 0 }];

      if (this.roleOptions[0].id_company) {
        this.roleOptions.forEach((element) => {
          if (element.id_company == this.companySelected) {
            element.text = element.nome;
            element.value = element.id;
            array.push(element);
          }
        });
        return array;
      } else {
        this.roleOptions.forEach((element) => {
          array.push(element);
        });
        return array;
      }
    },
    totalRows() {
      return this.partnerList ? this.partnerList.length : 0;
    },

    file_photo_url() {
      return this.file_photo ? URL.createObjectURL(this.file_photo) : "";
    },

    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
    currentCompanyFromStore() {
      this.companySelected = this.currentCompanyFromStore;
    },

    companySelected() {
      this.$store.dispatch(SET_CURRENT_COMPANY, this.companySelected);
    },
  },
};
</script>
